import { configureStore } from '@reduxjs/toolkit';
import currentListingReducer from './currentListingSlice';
import guestDataReducer from './guestDataSlice';
import paymentDataReducer from './paymentDataSlice';


const store = configureStore({
reducer: {
    currentListing: currentListingReducer,
    guestData: guestDataReducer,
    paymentData: paymentDataReducer,
},
});


export default store;