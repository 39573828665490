import React from 'react';
import styles from './TitleBox.module.css'
import Button from 'components/shared/Button/Button';

const TitleBox = ({title, onScrollClick, onOpenMapClick}) => {
  const startScroll = () => {
    onScrollClick()
  }

  const openMap = () => {
    onOpenMapClick()
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles['button-pane']}>
        {/*<Button handleClickFunc={startScroll} type='secondary'>Property Details</Button>  */}
        {/*<Button handleClickFunc={openMap}>View a Map</Button>  */}
      </div>        
    </div>
  );
}

export default TitleBox;