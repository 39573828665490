import React from 'react';
import styles from './AmenitiesBox.module.css';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const AmenitiesBox = ({ arr }) => {

  // Prepare the array by removing duplicates based on the name key, and sorting alphabetically

    const uniqueAmenities = (arr ? [...new Map(arr.map(item => [item['name'], item])).values()].sort((a, b) => a.name.localeCompare(b.name)) : null);

  return (
    <div className={styles['amenities-box']}>
      <h2 className={styles.title}>Amenities</h2>
      <div className={styles.container}>
        {uniqueAmenities && uniqueAmenities.map((item) => (
          <div key={item.id} className={styles.item}>
            <IoMdCheckmarkCircleOutline className={styles.icon}/> 
            <p>{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AmenitiesBox;