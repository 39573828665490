function prepareCitiesForDropdown(rawCitiesObject) {
    const result = [];

    for (const key in rawCitiesObject) {
        const label = rawCitiesObject[key];

        // if label is blank, skip this iteration

        if (!label) {
            continue;
        }

        // using label for both 'key' and 'label' on purpose for the sake of staying with the standard dropdown object
        const city = {
            label,
            key: label,
        };
        result.push(city);
    }

    return result;
}

export default prepareCitiesForDropdown;