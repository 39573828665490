import React, { useState } from 'react';
import styles from './MapMarker.module.css'
import { renderToString } from 'react-dom/server';
import { MarkerF } from '@react-google-maps/api';
import { FaMapMarker } from "react-icons/fa";

const MapMarker = ({position, listing, sendListing, appendToUrl}) => {
    const [isActive, setIsActive] = useState(false);
    const iconStyles = {
        fontSize: '44px', 
        color: isActive ? '#FF6F12' : '#4E8EC2',   
    };

    const handleMouseEnter = () => {
        setIsActive(true);
        sendListing(listing);
    };

    const handleMouseLeave = () => {
        setIsActive(false);
        sendListing(null);
    };

    const handleMouseClick = () => {
        window.location = `/listing-details/${listing.id}` + appendToUrl;
    }

    const markerIcon = renderToString(<FaMapMarker style={iconStyles}/>);
    const markerIconUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(markerIcon)}`;
    
    return (
        <MarkerF 
            onMouseOver={handleMouseEnter}
            onMouseOut={handleMouseLeave}
            onClick={handleMouseClick}
            position={position} 
            icon={markerIconUrl}             
        />
    );
}

export default MapMarker;