import React, { useState } from 'react';
import styles from './BurgerMenu.module.css';
import logo from 'assets/logoWhite.png'
import { GiHamburgerMenu } from "react-icons/gi";
import { LiaTimesSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";

const BurgerMenu = ({links}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (url) => {
    setIsOpen(false);
    window.location = url;
  }

  return (
    <>
    <div className={styles['burger-line']}>
        <div><img className={styles.logo} src={logo} alt="" width="30"/></div>
        <div onClick={toggleMenu} className={styles['burger-icon']}>
            {isOpen ? <LiaTimesSolid /> : <GiHamburgerMenu />}            
        </div>
    </div>
    {isOpen && <div className={styles.menu}>
        <ul>
            {links && links.map((item) => (
                <li className={styles.link} key={item.url} onClick={() => handleLinkClick(item.url)}>{item.title}</li>
            ))}
        </ul>
    </div>}
    </>
  );
};

export default BurgerMenu;