function getHearAboutUsOptions() {
    const options = [
        { key: 0, label: 'AirBnB' },
        { key: 1, label: 'Google' },
        { key: 2, label: 'Facebook' },
        { key: 3, label: 'Instagram' },
        { key: 4, label: 'A friend' },
        { key: 5, label: 'Other' }
    ];

    return options;
}

export default getHearAboutUsOptions;