import React from 'react';
import styles from './Header.module.css';
import logo from 'assets/logohome.png'
import Links from './Links/Links';
import Button from 'components/shared/Button/Button';
import BurgerMenu from 'components/shared/BurgerMenu/BurgerMenu';
import {Link} from "react-router-dom";

const Header = () => {
  const links = [
    {title: 'Home', url: process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH},
    {title: 'About', url: `${process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH}/about`},
    {title: 'Property Management', url: `${process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH}/property-management`},
    {title: 'FAQ', url: `${process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH}/faq`},
    {title: 'Contact', url: `${process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH}/contact`}
  ]
  return (
    <div className={styles['header-section']}>
        <div className={styles['header-wrapper']}>
          <div className={styles['header-container']}>
              <div className={styles["width-180"]}>
                  <a href={process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH}><img src={logo} alt="" className={styles["header-logo"]} /></a>
              </div>
            <Links links={links}/>
              <Link to={"/"} className={styles['header-book-now']}><Button type='secondary' width='180'>Book Now</Button></Link>
          </div>
        </div>
        <div className={styles['burger-wrapper']}>
          <BurgerMenu links={links}/>
        </div>
    </div>
  );
}

export default Header;