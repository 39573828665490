import React from 'react';
import styles from './ThreeImagePane.module.css'

const ThreeImagePane = ({ imgFiles }) => {
  const arr = imgFiles?.slice(1, 4);

  return (
    <div className={styles.container}>
      {arr && arr.map((item) => (
            <div key={item.id} className={styles['img-wrapper']} >
                <img className={styles.img} src={item.url} />                
            </div>
        ))} 
    </div>
  );
}

export default ThreeImagePane;