import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import styles from './Listing.module.css';
import HeroSection from 'components/HeroSection/HeroSection';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import imgUrl from 'assets/heroImages/heroListing.png';
import FindProperty from 'components/FindProperty/FindProperty';
import ListingItem from 'components/ListingItem/ListingItem';
import useTitle from 'hooks/useTitle';
import useFetchListings from 'hooks/useFetchListings';
import useFetchCities from 'hooks/useFetchCities';
import ControlBar from 'components/ListingItem/ControlBar/ControlBar';
import MPagination from 'components/shared/MPagination/MPagination';
import useFetchAllListings from 'hooks/useFetchAllListings';
import Map from './Map/Map';
import addDays from "../../utils/addDays";
import {setPaymentData} from "../../redux/paymentDataSlice";

const Listing = () => {
    useTitle('Listings');
    const HeroTitle                           = 'Book your Home Base'
    const [page, setPage]                     = useState(1);
    const [sort, setSort]                     = useState('');
    const [listingsActive, setListingsActive] = useState(true)
    const [isFetching, setIsFetching]         = useState(false);

    //  Get the query parameters from the URL
    const urlParams             = new URLSearchParams(window.location.search);
    const adults                = urlParams.get('adults');
    const children              = urlParams.get('children');
    const checkIn               = urlParams.get('checkIn');
    const checkOut              = urlParams.get('checkOut');
    const city                  = urlParams.get('city');
    const [filters, setFilters] = useState({
                                               adults:   adults || 1,
                                               children: children || 0,
                                               checkIn:  checkIn || addDays(3).toLocaleDateString('en-US'),
                                               checkOut: checkOut || addDays(6).toLocaleDateString('en-US'),
                                               city:     city || '',
                                           });


    const {
              listings,
              totalPages
          } = useFetchListings(page, sort, filters);

    const {cities} = useFetchCities();

    const filterListings = (filtersData) => {
        setFilters(filtersData);
        setPage(1); // Reset the page when applying filters
    };

    const handleOrderChange = (sortData) => {
        setSort(sortData);
        setPage(1); // Reset the page when changing the sorting column
    };

    const setNewPage = (newPage) => {
        setPage(newPage);
    };

    const handleListingActiveChange = (isActive) => {
        setListingsActive(isActive)
    }

    const {allListings} = useFetchAllListings(listingsActive, filters, setIsFetching)

    return (
        <div>
            <Header/>
            {/*<HeroSection title={HeroTitle} imageUrl={imgUrl} />*/}
            <section className={styles['main-section']}>

                <FindProperty cities={cities} onFilter={filterListings} initialFilters={filters}/>

                <div className={styles['main-pane']}>
                    <ControlBar isListingActive={listingsActive} onListingActiveChange={handleListingActiveChange}
                                onOrderChange={handleOrderChange}/>
                    {listingsActive ? (
                        <> {listings?.length ? <div className={styles['listings']}>
                            <div className={styles['listing-pane']}>
                                {listings && listings.map((listing) => (
                                    <a className={styles['listing-link']}
                                       href={`/listing-details/${listing.id}?adults=${filters.adults}&children=${filters.children}&checkIn=${filters.checkIn}&checkOut=${filters.checkOut}`}>
                                        <ListingItem listing={listing}/>
                                    </a>
                                ))}
                            </div>
                            <div className={styles['pagination-box']}>
                                <MPagination onPageChange={setNewPage} totalPages={totalPages} page={page}/>
                            </div>
                        </div> : <div className={styles['no-listings-wrapper']}>
                                <p className={styles['no-listings-text']}>
                                    {(
                                        isFetching ? 'Please wait while we load your results...' : (
                                            !listings?.length ? 'Sorry, no listings were found with these filters.' : ''
                                        )
                                    )}
                                </p>
                            </div>}
                        </>
                    ) : (
                         <div className={styles['map-wrapper']}>
                             <Map listings={allListings.data}
                                  appendToUrl={`?adults=${filters.adults}&children=${filters.children}&checkIn=${filters.checkIn}&checkOut=${filters.checkOut}`}/>
                         </div>
                     )}
                </div>
            </section>
            <Footer/>
        </div>
    );
};

export default Listing;