import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'redux/store';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

Sentry.init({
                dsn: "https://7a702e38c9cf5c5174681c242046fa88@o4504680995880960.ingest.sentry.io/4505963009802240",
                integrations: [
                    new Sentry.BrowserTracing({
                                                  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                                                  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
                                              }),
                    new Sentry.Replay(),
                ],
                // Performance Monitoring
                tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });

const root = ReactDOM.createRoot(document.getElementById('homebase-root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}> 
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);