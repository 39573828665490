import React from 'react';
import styles from './DetailsContainer.module.css'

const DetailsContainer = ({ data }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Property Details</h2>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: data?.description }} />
      <div className={styles.features}>
        <div className={styles.feature}>
            <span>Type:</span>
            <span className={styles['feature-value']}>{data.room_type}</span>
        </div>
        <div className={styles.feature}>
            <span>Bedrooms:</span>
            <span className={styles['feature-value']}>{data.bedrooms}</span>
        </div>
        {/*<div className={styles.feature}>*/}
        {/*    <span>Home size:</span>*/}
        {/*    <span className={styles['feature-value']}>{data.square_feet || 'n/a'}</span>*/}
        {/*</div>*/}
        <div className={styles.feature}>
            <span>Bathrooms:</span>
            <span className={styles['feature-value']}>{data.bathrooms}</span>
        </div>
        <div className={styles.feature}>
            <span>Sleeps:</span>
            <span className={styles['feature-value']}>{data.number_of_guests}</span>
        </div>
        <div className={styles.feature}>
            <span>Parties & Events:</span>
            <span className={styles['feature-value']}>Strictly Prohibited</span>
        </div>
      </div>
    </div>
  );
}

export default DetailsContainer;