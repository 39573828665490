function calculateOptimalPosition(coords, elementWidth, elementHeight) {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const spaceOnRight = screenWidth - (coords.x + elementWidth);
  const spaceOnLeft = coords.x;
  const spaceOnBottom = screenHeight - (coords.y + elementHeight);
  const spaceOnTop = coords.y;

  const availableSpaces = [spaceOnRight, spaceOnLeft, spaceOnBottom, spaceOnTop];
  const maxSpace = Math.max(...availableSpaces);

  let optimalLeft = coords.x + 20;
  let optimalTop = coords.y - 100;

  if (maxSpace === spaceOnRight && spaceOnRight >= elementWidth) {
      optimalLeft = coords.x + 20;
  } else if (maxSpace === spaceOnLeft && spaceOnLeft >= elementWidth) {
      optimalLeft = coords.x - elementWidth - 20;
  }

  if (maxSpace === spaceOnBottom && spaceOnBottom >= elementHeight) {
      optimalTop = coords.y + 20;
  } else if (spaceOnTop >= elementHeight) {
      optimalTop = coords.y - elementHeight - 20;
  }

  // Ensure the element stays within screen boundaries
  optimalLeft = Math.max(0, Math.min(screenWidth - elementWidth, optimalLeft));
  optimalTop = Math.max(0, Math.min(screenHeight - elementHeight, optimalTop));

  return { left: optimalLeft, top: optimalTop };
}
  
  export default calculateOptimalPosition;