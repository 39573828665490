import React from 'react';
import styles from './FooterColumn.module.css'
import { Link } from 'react-router-dom';

const FooterColumn = ({title, links}) => {
  return (
    <div className={styles.column}>
      <h3 className={styles.title}>{title}</h3>
        {
            links.map((link) => (
                (link.url ? <Link key={link.url} className={styles.link} to={link.url}>{link.title}</Link> : <div key={link.title} className={styles.link}>{link.title}</div>)
            ))
        }
    </div>
  );
}

export default FooterColumn;