import { useState, useEffect } from 'react';
import axios from 'axios';
import prepareFilters from 'utils/prepareFilters';
import prepareCitiesForDropdown from 'utils/prepareCitiesForDropdown';

const useFetchCities = (filters) => {
  const [cities, setCities] = useState([]);
  const fetchCitiesEndpoint = '/listings/cities';

  useEffect(() => {
    const fetchCities = async () => {
      try {

        const response = await axios.get(`${process.env.REACT_APP_API_URL}${fetchCitiesEndpoint}`);

        const { data } = response;
        const cities = prepareCitiesForDropdown(data);
        setCities(cities);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    }

    fetchCities();
  }, [filters]);

  return { cities };
};

export default useFetchCities;