import React from 'react';
import styles from './Gallery.module.css'
// hardcoded
import backyard from 'assets/backyard.png';
import kitchen from 'assets/kitchen.png';
import pool from 'assets/pool.png';
import views from 'assets/views.png';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


// / hardcoded


const Gallery = ({ imgArray }) => {

    const images = imgArray.map((item) => {
        return {
            original: item.url,
            thumbnail: item.url,
        }
    });

  return (
    <div className={styles.gallery}>
      <h2 className={styles.title}>Gallery</h2>
      <div className={styles.container}>

            <ImageGallery
                items={images}
                slideInterval={2000}
            />


      </div>
      {/*<div className={styles.includes}>*/}
      {/*    <h2 className={styles['includes-title']}>Includes Lifestyle</h2>*/}
      {/*    <div className={styles.amenities}>*/}
      {/*    /!* hardcoded *!/*/}
      {/*      <div className={styles.amenity}>*/}
      {/*        <img src={backyard} alt=''/>*/}
      {/*        <p>Backyard oasis</p>*/}
      {/*      </div>*/}
      {/*      <div className={styles.amenity}>*/}
      {/*        <img src={kitchen} alt=''/>*/}
      {/*        <p>Chef's kitchen</p>*/}
      {/*      </div>*/}
      {/*      <div className={styles.amenity}>*/}
      {/*        <img src={pool} alt=''/>*/}
      {/*        <p>Private pool</p>*/}
      {/*      </div>*/}
      {/*      <div className={styles.amenity}>*/}
      {/*        <img src={views} alt=''/>*/}
      {/*        <p>Amazing views</p>*/}
      {/*      </div>*/}
      {/*      /!* / hardcoded *!/*/}
      {/*    </div>*/}
      {/*</div>*/}
    </div>
  );
}

export default Gallery;