import React, { useState } from 'react';
import styles from './ListingItem.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentListing } from 'redux/currentListingSlice';
import AmenitiesBlock from './AmenitiesBlock/AmenitiesBlock';
import MRating from 'components/shared/MRating/MRating';
import Button from 'components/shared/Button/Button';
import watchIcon from 'assets/watchIcon.png'
import PaymentModal from 'components/PaymentModal/PaymentModal';
import { setPaymentData } from 'redux/paymentDataSlice';
import calculatePaymentData from 'utils/calculatePaymentData';

const ListingItem = ({ listing }) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  
  
  const paymentData = useSelector((state) => state.paymentData);
  const calculated = calculatePaymentData(listing, paymentData);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (event) => {
    event.preventDefault();
    console.log("PAYMENT DATA:");
    console.log(paymentData);
    dispatch(setCurrentListing(listing))
    dispatch(setPaymentData({
      ...paymentData,
      ...calculated
    }))
    setIsModalOpen(true);
  };

  const amenities = {
    beds: listing.bedrooms,
    bathrooms: listing.bathrooms,
    number_of_guests: listing.number_of_guests,
    minimum_price: listing.minimum_price,
  }

  return (
    <div
      className={styles['listing-item-card']}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      
      <div className={styles['img-block']}>
        <img className={styles.thumbnail} src={listing.files[0].url} alt="" />       
        {isHovered ? <img className={styles.watch} src={watchIcon} alt="" /> : null}        
          <div className={styles.wrapper}>
            <MRating rating={listing.rating}/>
          </div>
        <div className={styles.linear}></div>
      </div>
      <div className={styles['description-block']}>
        <p className={styles['place-title']}>{listing.address.city || 'No city'}</p>
        <h1 className={styles['listing-title']}>{listing.name.split(" - ")[1]}</h1>
      </div>
      {isHovered ? <Button handleClickFunc={handleOpenModal}>Book Now</Button> : <AmenitiesBlock amenities={amenities} />}
      {isModalOpen && <PaymentModal 
        open={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
      /> }
    </div>
  );
};

export default ListingItem;