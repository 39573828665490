import React from 'react';
import styles from './MRating.module.css'
import Rating from '@mui/material/Rating';

const MRating = ({rating}) => {

return (
    <div className={styles.wrapper}>
        <p className={styles.value}>{rating?.toFixed(1)}</p>  
        <Rating
            name="simple-controlled"
            value={rating}
            precision={0.1}
            readOnly
        />
    </div>
  );
}

export default MRating;