import React from 'react';
import styles from './UnderHeroSection.module.css'
import { useSelector } from 'react-redux';
import formatDate from 'utils/formatDate';

const   UnderHeroSection = () => {
  const { checkIn, checkOut, price, total, subTotal, days } = useSelector(state => state.paymentData);

  const formattedCheckIn = formatDate(new Date(checkIn));
  const formattedCheckOut = formatDate(new Date(checkOut));

  return (
    <div className={styles['underhero-section']}>
      <div className={styles.item}>
        <div className={styles.label}>Check-In</div>
        <div className={styles.value}>{formattedCheckIn}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>Check-Out</div>
        <div className={styles.value}>{formattedCheckOut}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>Price</div>
        <div className={styles.value}>${(subTotal / days).toFixed(2)} per night</div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>Total</div>
        <div className={styles.value}>${total.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
      </div>
    </div>
  );
}

export default UnderHeroSection;