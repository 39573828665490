import { createSlice } from '@reduxjs/toolkit';

const currentListingSlice = createSlice({
  name: 'currentListing',
  initialState: null,
  reducers: {
    setCurrentListing: (state, action) => {
      return action.payload;
    },
  },
});

export const { setCurrentListing } = currentListingSlice.actions;
export default currentListingSlice.reducer;
