import React, {useState, useEffect} from 'react';
import styles from './FindProperty.module.css';
import {useDispatch, useSelector} from 'react-redux';
import {setPaymentData} from 'redux/paymentDataSlice';
import Button from 'components/shared/Button/Button';
import MDropdown from 'components/shared/MDropdown/MDropdown';
import addDays from 'utils/addDays';
import MDatepicker from 'components/shared/MDatepicker/MDatepicker';
import MCheckBox from 'components/shared/MCheckBox/MCheckBox';
import generateQuantityArray from 'utils/generateQuantityArray';
import getAmenitiesCheckboxItems from 'utils/getAmenitiesCheckboxItems';
import app from "../../App";
import TextField from "@mui/material/TextField";
import * as fecha from "fecha";
import HotelDatepicker from "hotel-datepicker";


const FindProperty = ({
                          cities,
                          onFilter,
                          initialFilters
                      }) => {
    const dispatch                                                  = useDispatch()
    const paymentData                                               = useSelector((state) => state.paymentData);
    const [filters, setFilters]                                     = useState(initialFilters);
    const [shouldHandleInitialSubmit, setShouldHandleInitialSubmit] = useState(true);


    const handleSubmit = () => {
        onFilter(filters)
        dispatch(setPaymentData(filters));
    }

    const quantities = generateQuantityArray(20);

    const checkBoxItems = getAmenitiesCheckboxItems()

    const applyValue = (
        property,
        value
    ) => {
        setFilters((prevFilters) => (
            {
                ...prevFilters,
                [property]: value
            }
        ));
        //  Update the URL with the new value
        const queryParameters = new URLSearchParams(window.location.search);
        queryParameters.set(property, value);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryParameters}`);
    }

    const handleValueChange = (
        property,
        value
    ) => {
        applyValue(property, value)
    };

    const handleDateChange = () => {

        let value = window.datepicker.getValue();

        // The value is in format "MM/DD/YYYY - MM/DD/YYYY", so we need to split it.

        let dates    = value.split(" - ");
        let checkIn  = dates[0];
        let checkOut = dates[1];

        applyValue("checkIn", checkIn)
        applyValue("checkOut", checkOut)
    };

    const handleAmenitiesChange = (newArr) => {
        setFilters((prevFilters) => (
            {
                ...prevFilters,
                amenities: [...newArr]
            }
        ));
    }

    const [startDate, setStartDate] = useState(filters.checkOut)

    useEffect(() => {
        console.log('checkin has been changed')

        if (filters.checkIn && !filters.checkOut) {
            setFilters((prevFilters) => (
                {
                    ...prevFilters,
                    checkOut: addDays(1, filters.checkIn),
                }
            ));
        }
        setStartDate(addDays(1, filters.checkIn))
    }, [filters.checkIn]);

    useEffect(() => {
        onFilter(filters)
        dispatch(setPaymentData(filters));
    }, [filters])

    /*  This is only needed to send initial request to the API only after filters.checkIn and filters.checkOut are set
     But not to trigger request on any of the filters.checkIn or filters.checkOut change without clicking submit button.
     Initial request without these two parameters won't return data
     Request on filters.checkIn or filters.checkOut change whithout before clicking on the submit button is not correct behaviour */
    // useEffect(() => {
    //   if (filters.checkIn && filters.checkOut && shouldHandleInitialSubmit) {
    //     setShouldHandleInitialSubmit(false);
    //     handleSubmit();
    //   }
    // }, [filters.checkIn, filters.checkOut]);

    const myRef = React.createRef();

    useEffect(() => {

        let options = {
            format:         "MM/DD/YYYY",
            autoClose:      true,
            startDate:      fecha.format(new Date(), "MM/DD/YYYY"),
            onSelectRange:  handleDateChange,
            moveBothMonths: true,
        };

        if (!window.datepicker) {
            window.datepicker = new HotelDatepicker(myRef.current, options);
            // window.datepicker.setValue(checkIn + " - " + checkOut);
        }

    }, []);

    return (
        <div className={styles.card}>
            <h2 className={styles.title}>Find Property</h2>

            <div className={`${styles['destination-row']} ${styles['row']}`}>
                <div className={styles['destination-box']}>
                    <div className={styles['row-title']}>DESTINATION</div>
                    <MDropdown
                        options={cities}
                        notSelectedValue='All destinations'
                        displayEmpty={true}
                        defaultValue={filters.city || ''}
                        onChange={(value) => handleValueChange('city', value)}/>
                </div>
            </div>
            <div className={`${styles['adult-children-row']} ${styles['row']}`}>
                <div>
                    <div className={styles['row-title']}>ADULTS</div>
                    <MDropdown
                        options={quantities}
                        defaultValue={filters.adults || 1}
                        onChange={(value) => handleValueChange('adults', value)}/>
                </div>
                <div>
                    <div className={styles['row-title']}>CHILDREN</div>
                    <MDropdown
                        defaultValue={filters.children || 0}
                        options={quantities}
                        onChange={(value) => handleValueChange('children', value)}/>
                </div>
            </div>
            <div className={`${styles['dates-row']} ${styles['row']}`}>
                <div className={styles['row-title']}>DATES</div>

                <div id={"dates_container"} ref={myRef}>
                    {/*<div className={styles['date-picker-box']}>*/}
                    {/*    <MDatepicker*/}
                    {/*        onDateChange={(value) => handleDateChange('checkIn', value)}*/}
                    {/*        defaultDate={filters.checkIn}*/}
                    {/*        label="CHECK IN"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className={styles['date-picker-box']}>*/}
                    {/*    <MDatepicker*/}
                    {/*        onDateChange={(value) => handleDateChange('checkOut', value)}*/}
                    {/*        defaultDate={filters.checkOut}*/}
                    {/*        startDate={startDate}*/}
                    {/*        label="CHECK OUT"*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <TextField label={"CHECK IN"} placeholder="Dates"
                               fullWidth
                               variant="standard"
                               value={paymentData.checkIn}/>

                    <TextField label={"CHECK OUT"} placeholder="Dates"
                               fullWidth
                               variant="standard"
                               value={paymentData.checkOut}/>


                </div>
            </div>
            <div className={`${styles['rooms-row']} ${styles['row']}`}>
                <div className={styles['row-title']}>NUMBER OF ROOMS</div>
                <div className={styles['rooms-item-row']}>
                    <div className={styles['rooms-item-box']}>
                        <MDropdown label='Bed Rooms' options={quantities}
                                   onChange={(value) => handleValueChange('bedrooms', value)}/>
                    </div>
                    <div className={styles['rooms-item-box']}>
                        <MDropdown label='Bath Rooms' options={quantities}
                                   onChange={(value) => handleValueChange('bathrooms', value)}/>
                    </div>
                </div>
            </div>
            <div className={`${styles['amenities-row']} ${styles['row']}`}>
                <MCheckBox onSelectionChange={handleAmenitiesChange} options={checkBoxItems}/>
            </div>
            <div className={styles['button-box']}>
                <Button handleClickFunc={handleSubmit}>Search</Button>
            </div>
        </div>
    );
}

export default FindProperty;