import React from 'react';
import styles from './Button.module.css'; // Create this CSS file to style the button

const Button = ({ children, handleClickFunc, type = 'primary', btnType = 'button', disabled = false }) => {
  // By Default !!!
  let buttonStyle = styles['primary']; 

  if(type === 'primary') {

  } else if (type === 'secondary') {
    buttonStyle = styles['secondary']; // Add secondary style
  } else if (type === 'outline-primary') {
    buttonStyle = styles['outline-primary']; // Add secondary style
  } 

  return (
    <button type={btnType} disabled={disabled} onClick={handleClickFunc} className={`${styles['custom-button']} ${buttonStyle}`} >
      <span>{children}</span>
    </button>
  );
};

export default Button;