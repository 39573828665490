import { useState, useEffect } from 'react';

const useFixedCardScroll = (stopPoint) => {
  const [isFixed, setIsFixed] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const curScrollPosition = window.scrollY;

      if (curScrollPosition > stopPoint) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [stopPoint]);

  return isFixed;
};

export default useFixedCardScroll;