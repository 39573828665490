import {useState, useEffect} from 'react';
import axios from 'axios';
import prepareFilters from 'utils/prepareFilters';
import getRandomListings from 'utils/getRandomListings';


const useFetchFeaturedListings = (filters) => {
    const [listings, setListings] = useState([]);
    const endpoint                = '/listings/get_available';
    const numberOfListingsNeeded  = 3;

    useEffect(() => {
        const fetchListings = async () => {
            try {
                const params = {
                    ...prepareFilters(filters),
                }

                const response = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                    params,
                });

                const {data}           = response.data;
                //data.data changed to just data
                const featuredListings = getRandomListings(data, numberOfListingsNeeded)
                setListings(featuredListings);
            }
            catch (error) {
                console.error('Error fetching listings:', error);
            }
        };

        fetchListings();
    }, []);

    return {listings};
};

export default useFetchFeaturedListings;