const getRandomListings = (listings, randomNumber) => {
    const shuffledListings = [...listings];
    for (let i = shuffledListings.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledListings[i], shuffledListings[j]] = [shuffledListings[j], shuffledListings[i]];
    }
    const threeRandomListings = shuffledListings.slice(0, randomNumber);
    return threeRandomListings;
  }

export default getRandomListings;  