const getAmenitiesCheckboxItems = () => {
    const amenitiesArray = [
        {
          label: 'Air Conditioning',
          key: 'Air conditioning',
        },
        {
          label: 'Garage',
          key: 'Garage',
        },
        {
          label: 'Pool',
          key: 'Pool',
        },
        {
          label: 'Backyard',
          key: 'Backyard',
        }
      ] 

    return amenitiesArray;
  };
  
  export default getAmenitiesCheckboxItems;