import React from 'react';
import styles from './UnderHeroDetails.module.css';
import MRating from 'components/shared/MRating/MRating';

const UnderHeroDetails = ({listing}) => {
  
  return (
    <div className={styles['under-hero-details']}>
      
      <div className={styles.item}>
        <p className={styles.label}>Price</p>
        <p className={styles.value}>$ {listing.minimum_price} per night</p>
      </div>
      <div className={styles.item}>
        <p className={styles.label}>State</p>
        <p className={styles.value}>{listing.address?.state || ''}</p>
      </div>
      <div className={styles.item}>
        <p className={styles.label}>Location</p>
        <p className={styles.value}>{listing.address?.city || ''}</p>
      </div>
      {listing.rating !== undefined && (
      <div className={styles.item}>
        <p className={styles.label}>Rating</p>
        <div className={styles.wrapper}>
          <MRating rating={listing.rating}/>
        </div>
      </div>  
         )}
    </div>
  );
}

export default UnderHeroDetails;