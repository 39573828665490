import { useState, useEffect } from 'react';
import axios from 'axios';
import prepareFilters from 'utils/prepareFilters';


const useFetchListings = (currentPage, sort, filters) => {
  const [listings, setListings] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [allCities, setAllCities] = useState([]);
  const listingsPerPage = 10;
  const endpoint = '/listings/get_available';

  useEffect(() => {
    const fetchListings = async () => {
      try {

        const params = {
          page: currentPage,
          orderByCol: sort ? sort.col : "code",
          orderByDir: sort ? sort.dir : "asc",
          page_size: listingsPerPage,
          ...prepareFilters(filters),
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`, {
          params,
        });

        const { data } = response.data;
        setListings(data.data);
        setTotalPages(data.last_page);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };

    fetchListings();
  }, [currentPage, sort, filters]);

  return { listings, totalPages }
};

export default useFetchListings;