import React, { useMemo, useState, useEffect } from 'react';
import styles from './Map.module.css';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import MapMarker from 'components/shared/MapMarker/MapMarker';
import CircularProgress from '@mui/material/CircularProgress';
import ListingItem from 'components/ListingItem/ListingItem';
import useTrackMouse from 'hooks/useTrackMouse';
import calculateOptimalPosition from 'utils/calculateOptimalPosition';

const Map = ({ listings, appendToUrl }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });

    const center = useMemo(() => ({ lat: 39.76, lng: -86.15 }), []);

    const [currentListing, setCurrentListing] = React.useState(null)

    const handleMarkerEvent = (listing = null) => {
        setCurrentListing(listing)
    }

    const coords = useTrackMouse(currentListing);

    const elementWidth = 587;
    const elementHeight = 466;
    const optimalPosition = calculateOptimalPosition(coords, elementWidth, elementHeight);


    return (
        <div className={styles.container}>
            {isLoaded && listings ? (
                <GoogleMap zoom={7} center={center} mapContainerClassName={styles['map-container']}>
                    {listings.map((listing) => (
                        <MapMarker 
                            key={listing.id}
                            sendListing={handleMarkerEvent}
                            listing={listing} 
                            position={JSON.parse(listing.coordinates)}
                            appendToUrl={appendToUrl}
                        />
                    ))}
                </GoogleMap>
            ) : (
                <div className={styles['loader-wrapper']}>
                    <CircularProgress />
                </div>
            )}
            {currentListing && (
                <div 
                className={styles['listing-item-wrapper']}
                style={{ 
                    left: optimalPosition.left, 
                    top: optimalPosition.top,
                    }}>
                    <ListingItem listing={currentListing} />
                </div>
            )}
        </div>
    );
};

export default Map;