import {useState, useEffect} from 'react';
import axios from 'axios';
import prepareFilters from 'utils/prepareFilters';

const useFetchAllListings = (
    listingsActive,
    filters,
    setIsFetching
) => {
    // add conditional logic if listingsActive === true, no need to make a request
    const [allListings, setAllListings] = useState([]);

    useEffect(() => {
        const fetchAllListings = async () => {
            try {

                setIsFetching(true);

                const endpoint = '/listings/get_available';
                const params   = {
                    ...prepareFilters(filters),
                }

                const response = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                    params,
                });

                const {data} = response;
                setAllListings(data);

                setIsFetching(false);
                
            }
            catch (error) {
                console.error('Error fetching all listings:', error);
            }
        };

        fetchAllListings();
    }, [listingsActive]);

    return {allListings};
};

export default useFetchAllListings;