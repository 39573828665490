import React from 'react';
import './AmenitiesBlock.css';
import { TbBed } from "react-icons/tb";
import { TbBath } from "react-icons/tb";
import { FaUserGroup } from "react-icons/fa6";
import { FaDollarSign } from "react-icons/fa6";

const AmenitiesBlock = ({ amenities }) => {
  return (
    <div className='amenities-block'>
      <p><span><TbBed /></span>{ amenities.beds }</p>
      <p><span><TbBath /></span>{ amenities.bathrooms }</p>
      <p><span><FaUserGroup /></span>{ amenities.number_of_guests }</p>
      <p><span><FaDollarSign /></span>{ amenities.minimum_price }</p>
    </div>
  );
}

export default AmenitiesBlock;