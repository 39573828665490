import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import styles from './YouMayLike.module.css';
import ListingItem from 'components/ListingItem/ListingItem';
import useFetchFeaturedListings from 'hooks/useFetchFeaturedListings';
import addDays from 'utils/addDays';
import {useSelector} from "react-redux";

const YouMayLike = () => {
    const defaultCheckin = addDays(1);
    const defaultCheckout = addDays(3);

    const paymentData = useSelector((state) => ({ ...state.paymentData }));

    const filters = {
        checkIn: defaultCheckin,
        checkOut: defaultCheckout
    }
    const { listings } = useFetchFeaturedListings(paymentData)
  
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>You May Also Like</h2>
      <p className={styles.description}>View some of our featured properties selected just for you!</p>
      <div className={styles['listings-box']}>
            {listings && listings.map((listing) => (
                <a key={listing.id} className={styles['listing-link']} href={`/listing-details/${listing.id}?adults=${paymentData.adults}&children=${paymentData.children}&checkIn=${paymentData.checkIn}&checkOut=${paymentData.checkOut}`}>
                    <ListingItem listing={listing} />
                </a>
            ))}
      </div>
    </div>
  );
}

export default YouMayLike;