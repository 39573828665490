import './App.css';
import React from 'react';
import { Routes, Route } from "react-router-dom";
import Listing from 'pages/Listing/Listing';
import ListingDetails from 'pages/ListingDetails/ListingDetails';

function App() {
  return (
      <Routes>
        <Route path="*" element={<Listing />} />
        <Route path="/listing-details/:id" element={<ListingDetails />} />
          {/*<Route path="*" element={<b>404</b>} />*/}
      </Routes>
  );
}

export default App;
