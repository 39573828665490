const prepareFilters = (filters) => {
    const data = {};
    // initial guests requirements
    data['min[number_of_guests]'] = 0;

    if (filters.city) {
      data['exact[address.city]'] = filters.city;
    }
    if (filters.bedrooms) {
      data['exact[bedrooms]'] = filters.bedrooms;
    }
    if (filters.bathrooms) {
      data['exact[bathrooms]'] = filters.bathrooms;
    }
    if (filters.adults) {
      data['min[number_of_guests]'] += filters.adults;
    }
    if (filters.children) {
      data['min[number_of_guests]'] += filters.children;
    }
    if (filters.amenities) {
      const amenityKeys = filters.amenities.map((amenity) => amenity.key);
      data['in[amenities.name]'] = amenityKeys;
    }
    
    if (filters.checkIn) {
      const formattedDate = filters.checkIn ? 
      new Date(filters.checkIn).toLocaleDateString('en-US') 
      : 
      filters.checkIn.toLocaleDateString('en-US');
      // not for the "filter" function, so should not be in format 'exact' or 'min' or something
      data['checkIn'] = formattedDate;
    }
    if (filters.checkOut) {
      const formattedDate = filters.checkOut ? 
      new Date(filters.checkOut).toLocaleDateString('en-US') 
      : 
      filters.checkOut.toLocaleDateString('en-US');
      data['checkOut'] = formattedDate;
    }
  
    return data;
  };
  
  export default prepareFilters;