import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const MPagination = ({totalPages, onPageChange}) => {
    const [page, setPage] = React.useState(1);
    const handleChange = (evt, newPage) => {
        setPage(newPage)
        onPageChange(newPage)
    }
  
    return (
      <Stack spacing={2}>
        <Pagination count={totalPages} page={page} onChange={handleChange} />
      </Stack>
    );
}

export default MPagination;