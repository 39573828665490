import React from 'react';
import styles from './Links.module.css';
import { Link } from 'react-router-dom';

const Links = ({links}) => {
  return (
    <div className={styles['wrapper']}>
      {links.map((link) => (
          <Link className={styles['link']} key={link.url} to={link.url}>
            {link.title}
          </Link>    
      ))}
    </div>
  );
}

export default Links;