const getOptionsForNumberOfGuests = (amount) => {
    const opts = [];

    for (let i = 0; i <= amount; i++) {
        opts.push({
            key: i,
            label: i
        });
    }  

    return opts;
  };
  
export default getOptionsForNumberOfGuests;