import React, { useState } from 'react';
import styles from './MDropdown.module.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const MDropdown = ({ 
    options, 
    notSelectedValue="None", 
    displayEmpty= false, 
    label = null, 
    onChange, 
    defaultValue = "", 
    readOnly = false 
  }) => {

  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleChange = (event) => {
    if(!onChange) return
    const selectedValue = event.target.value;
    setSelectedValue(selectedValue);
    onChange(selectedValue); 
  };

  React.useEffect(() => {
    setSelectedValue(defaultValue)
  }, [defaultValue])

  return (
        <div className={styles['form-control-wrapper']}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            {/* if no label passed as a prop, don't render */}
            {label && <InputLabel id="demo-select-small-label">{label}</InputLabel>}
            <Select
              id="demo-simple-select-standard"
              value={selectedValue}
              onChange={handleChange}
              inputProps={{ readOnly }}
              displayEmpty={displayEmpty}
            >
              <MenuItem value="">
                <em>{notSelectedValue}</em>
              </MenuItem>
              {options && options.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
  );
};

export default MDropdown;