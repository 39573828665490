import { useState, useEffect } from 'react';

function useTrackMouse(currentListing) {
    const [coords, setCoords] = useState({ x: 0, y: 0 });
    const [prevCoords, setPrevCoords] = useState({ x: 0, y: 0 });

    useEffect(() => {
        if (!currentListing) {
            const handleMouseMove = (e) => {
                setCoords({ x: e.clientX, y: e.clientY });
            };

            window.addEventListener('mousemove', handleMouseMove);

            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
            };
        } else {
            // Store the previous coordinates when currentListing is not null
            setPrevCoords(coords);
        }
    }, [currentListing, coords]);

    if (currentListing) {
        // If currentListing is not null then the mouse is moving within the marker borders, no need to recalculate the mouse position
        // Return the previous coordinates when currentListing is not null
        return prevCoords;
    }

    return coords;
}

export default useTrackMouse;