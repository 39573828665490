const calculatePaymentData = (
    listingData,
    paymentData
) => {
    const msToDays = (ms) => {
        const millisecondsInADay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        return ms / millisecondsInADay; // Convert milliseconds to days
    };

    // Helper function to format date as YYYY-MM-DD
    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };

    const checkInDate  = new Date(paymentData?.checkIn);
    const checkOutDate = new Date(paymentData?.checkOut);
    const ms           = checkOutDate - checkInDate;
    const days         = Math.round(msToDays(ms)); // Ensure days is a whole number
    let subTotal       = 0;

    for (
        let i = 0;
        i < days;
        i++
    ) {
        const currentDate = new Date(checkInDate);
        currentDate.setDate(checkInDate.getDate() + i);
        const formattedDate = formatDate(currentDate);

        // Check if there's a listing exception for the current date
        const exception = listingData.listing_exceptions?.find(exception => exception.date === formattedDate);

        // Use the exception price if available; otherwise, use the minimum price
        const priceForDay = exception ? exception.price : listingData.minimum_price;
        subTotal += priceForDay;
    }

    const cleaningFee   = listingData.cleaning_fee;
    const taxes         = (
                              subTotal + cleaningFee
                          ) * listingData.tax_rate;
    const managementFee = 15; // Hardcoded management fee
    const total         = subTotal + taxes + cleaningFee + managementFee;

    return {
        days,
        price:    listingData.minimum_price, // Default price for reference
        total,
        subTotal,
        taxes,
        discount: 0, // Assuming no discount logic change
    };
}

export default calculatePaymentData;
