import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FaSquareCheck } from "react-icons/fa6";

const MCheckBox = ({ options, onSelectionChange, isRequired = false }) => {
    let selectedItems = [];

    const handleChange = (event, item) => {
      const isChecked = event.target.checked;

      selectedItems = isChecked
        ? [...selectedItems, item]
        : selectedItems.filter(selectedItem => selectedItem !== item);
      
        onSelectionChange(selectedItems);
    };
  return (
        <FormGroup>
            {
                options.map((item) => (
                    <FormControlLabel 
                        key={item.key} 
                        control={
                        <Checkbox 
                        required={isRequired}
                        onChange={(event) => handleChange(event, item)} 
                        checkedIcon={<FaSquareCheck style={{color: "#FF6F12", fontSize: "24px"}} 
                        />} />}
                        label={item.label}  
                    />
                ))
            }      
        </FormGroup>
  );
}

export default MCheckBox;