const getOrderOptions = () => {
    const nameForPriceCol = 'minimum_price';
    const nameForReviewsCol = 'rating';

    return [
        {
          label: 'Price per night: low to high',
          key: 1,
          value: {
            col: nameForPriceCol,
            dir: 'asc'
          }
        },
        {
          label: 'Price per night: high to low',
          key: 2,
          value: {
            col: nameForPriceCol,
            dir: 'desc'
          }
        },
        {
          label: 'Reviews: high to low',
          key: 3,
          value: {
            col: nameForReviewsCol,
            dir: 'desc'
          }
        }
      ]
}

export default getOrderOptions;