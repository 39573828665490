import React, { useState, useEffect } from 'react';
import styles from './GuestDetails.module.css'
import TextField from '@mui/material/TextField';
import getAllStates from 'utils/getAllStates';
import MDropdown from 'components/shared/MDropdown/MDropdown';
import getTimePeriods from 'utils/getTimePeriods';
import getHearAboutUsOptions from 'utils/getHearAboutUsOptions';

const GuestDetails = ({ onGuestChange }) => {
  const [guestDetails, setGuestDetails] = useState({});
  const states = getAllStates()

  const timePeriods = getTimePeriods();
  const hearAboutUsOptions = getHearAboutUsOptions();

  const onInputChange = (payload, property) => {

    // don't change state earlier than 500ms 
    setGuestDetails((prevDetails) => ({ ...prevDetails, [property]: payload.target.value }));
  };

  const handleDropdownChange = (property, value) => {
    setGuestDetails((prevDetails) => ({ ...prevDetails, [property]: value }));
  };

  useEffect(() => {
    onGuestChange(guestDetails);
  }, [guestDetails]);

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Guest Details</h3>

      <div className={styles.row}>
        <div className={`${styles['row-item']} ${styles['half-width']}`}>
          <div className={styles['row-title']}>First Name</div>
          <TextField required onChange={payload => onInputChange(payload, 'firstName')} fullWidth variant="standard" />
        </div>
        <div className={`${styles['row-item']} ${styles['half-width']}`}>
          <div className={styles['row-title']}>Last Name</div>
          <TextField required onChange={payload => onInputChange(payload, 'lastName')} fullWidth variant="standard" />
        </div>
      </div>

      {/*<div className={styles.row}>*/}
      {/*  <div className={styles['row-item']}>*/}
      {/*    <div className={`${styles['row-title']} ${styles['address']}`}>ADDRESS</div>*/}
      {/*    <TextField required onChange={payload => onInputChange(payload, 'address')} fullWidth variant="standard" />*/}
      {/*  </div>*/}
      {/*  <div className={`${styles['row-item']} ${styles['half-width']}`}>*/}
      {/*    <div className={styles['row-title']}>APT.</div>*/}
      {/*    <TextField onChange={payload => onInputChange(payload, 'apt')} placeholder="-" fullWidth variant="standard" />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className={styles.row}>*/}
      {/*  <div className={`${styles['row-item']} ${styles['one-third-width']}`}>*/}
      {/*    <div className={styles['row-title']}>CITY</div>*/}
      {/*    <TextField required onChange={payload => onInputChange(payload, 'city')} placeholder="-" fullWidth variant="standard" />*/}
      {/*  </div> */}
      {/*  <div className={`${styles['row-item']} ${styles['one-third-width']}`}>*/}
      {/*    <div className={styles['row-title']}>STATE</div>*/}
      {/*      <MDropdown */}
      {/*        options={states} */}
      {/*        label="" */}
      {/*        onChange={(value) => handleDropdownChange('state', value)}*/}
      {/*      />*/}
      {/*  </div>*/}
      {/*  <div className={`${styles['row-item']} ${styles['one-third-width']}`}>*/}
      {/*    <div className={styles['row-title']}>ZIP</div>*/}
      {/*    <TextField required onChange={payload => onInputChange(payload, 'zip')} placeholder="-" fullWidth variant="standard" />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className={styles.row}>
        <div className={`${styles['row-item']} ${styles['half-width']}`}>
          <div className={styles['row-title']}>Phone Number</div>
          <TextField required onChange={payload => onInputChange(payload, 'phone')} placeholder="+1" fullWidth variant="standard" />
        </div>
        <div className={`${styles['row-item']} ${styles['half-width']}`}>
          <div className={styles['row-title']}>Email Address</div>
          <TextField required onChange={payload => onInputChange(payload, 'email')} placeholder="-" fullWidth variant="standard" />
        </div> 
      </div>
      <div className={styles.row}>
        <div className={styles['row-item']}>
          <div className={`${styles['row-title']}`}>Special Requests</div>
          <TextField onChange={payload => onInputChange(payload, 'special')} placeholder="Any special requests?" fullWidth variant="standard" />
        </div> 
      </div>
      <div className={styles.row}>
        <div className={styles['row-item']}>
          <div className={`${styles['row-title']}`}>Expected Arrival Time</div>
          <MDropdown 
              options={timePeriods} 
              label="" 
              onChange={(value) => handleDropdownChange('arrival', value)}
            />
        </div> 
      </div>
      <div className={styles.row}>
        <div className={styles['row-item']}>
          <div className={`${styles['row-title']}`}>Where did you hear about us?</div>
          <MDropdown 
              options={hearAboutUsOptions} 
              label="" 
              onChange={(value) => handleDropdownChange('aboutus', value)}
            />
        </div> 
      </div>
    </div>
  );
}

export default GuestDetails;