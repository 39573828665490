import React, {useRef} from 'react';
import styles from './ListingDetails.module.css';
import HeroSection from 'components/HeroSection/HeroSection';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import useTitle from 'hooks/useTitle';
import FixedCard from './FixedCard/FixedCard';
import TitleBox from './TitleBox/TitleBox';
import UnderHeroDetails from './UnderHeroDetails/UnderHeroDetails';
import {Navigate, useParams} from 'react-router-dom';
import useFetchListingDetails from 'hooks/useFetchListingDetails';
import ThreeImagePane from './UnderHeroDetails/ThreeImagePane/ThreeImagePane';
import DetailsContainer from './DetailsContainer/DetailsContainer';
import Gallery from './Gallery/Gallery';
import AmenitiesBox from './AmenitiesBox/AmenitiesBox';
import YouMayLike from './YouMayLike/YouMayLike';
import useFixedCardScroll from 'hooks/useFixedScroll';
import * as PropTypes from "prop-types";

function Redirect(props) {
    return null;
}

Redirect.propTypes   = {to: PropTypes.string};
const ListingDetails = () => {
    // temp
    const {id} = useParams();

    // get checkIn, checkOut, and adults from the URL paramaters
    const urlParams = new URLSearchParams(window.location.search);
    const checkIn   = urlParams.get('checkIn');
    const checkOut  = urlParams.get('checkOut');
    const adults    = urlParams.get('adults');

    const {listing} = useFetchListingDetails(id, checkIn, checkOut, adults);
    useTitle('Listing details');

    const HeroTitle = listing.name ? listing.name.split(" - ")[1] : null;

    const sortedImages = listing?.files ? [...listing.files].sort((
                                                                      a,
                                                                      b
                                                                  ) => a.position - b.position) : [];

    // remove duplicates based on the url key
    const uniqueImages = [...new Map(sortedImages.map(item => [item['url'], item])).values()];

    const heroImgUrl = sortedImages ? sortedImages[0]?.url : ''

    const detailsSectionRef         = useRef(null);
    const amenitiesSectionRef       = useRef(null);
    // const stopPoint = amenitiesSectionRef.current ? amenitiesSectionRef.current.offsetTop : 0;
    const [stopPoint, setStopPoint] = React.useState(0);
    const isFixed                   = useFixedCardScroll(stopPoint);

    const fixedTop = 200
    const absTop   = stopPoint + fixedTop;

    const scrollToDetails = () => {
        if (detailsSectionRef.current) {
            detailsSectionRef.current.scrollIntoView({
                                                         behavior: 'smooth',
                                                         block:    'start',
                                                     });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
                            top:      0,
                            behavior: 'smooth',
                        });
    };

    const openGoogleMaps = (
        latitude,
        longitude
    ) => {
        const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(url, '_blank');
    };

    const handleOpenMap = () => {
        const coordinateString = listing.coordinates;
        const coordinateObject = JSON.parse(coordinateString);

        const latitude  = coordinateObject.lat;
        const longitude = coordinateObject.lng;
        openGoogleMaps(latitude, longitude);
    };

    React.useEffect(() => {
        scrollToTop()
    }, [])

    React.useEffect(() => {
        if (amenitiesSectionRef.current) {
            //the approximate gap to stick the FixedCard to the amenities section before the amenities section starts
            // moving behind the top
            const gap = Math.floor(window.innerHeight / 50);
            setStopPoint(amenitiesSectionRef.current.offsetTop - gap);
        }
    }, [listing]);

    return (
        (
            listing.pricing && !listing.pricing.is_available ? <Navigate
                to={`/`}/> : <div
                className={styles['listing-details-page']}>
                <Header/>
                <HeroSection imageUrl={heroImgUrl}>
                    <TitleBox onScrollClick={scrollToDetails} onOpenMapClick={handleOpenMap}
                              className={styles['hero-title']} title={HeroTitle}/>
                </HeroSection>
                <div style={{
                    top:      isFixed ? `${fixedTop}px` : `${absTop}px`,
                    position: isFixed ? 'fixed' : 'absolute',
                }}
                     className={styles['fixed-card-wrapper']}>
                    <FixedCard listingData={listing}/>
                </div>
                <section className={styles['under-hero-section']}>
                    <ThreeImagePane imgFiles={sortedImages}/>
                    <UnderHeroDetails listing={listing}/>
                </section>
                <div className={styles['relative-card-wrapper']}>
                    <FixedCard listingData={listing}/>
                </div>
                <section className={styles['details-section']} ref={detailsSectionRef}>
                    <DetailsContainer data={listing}/>
                </section>
                <section className={styles['gallery-section']}>
                    <Gallery imgArray={uniqueImages}/>
                </section>
                <section ref={amenitiesSectionRef} className={styles['amenities-section']}>
                    <AmenitiesBox arr={listing.amenities}/>
                </section>
                <section className={styles['you-may-like-section']}>
                    <YouMayLike/>
                </section>
                <Footer/>
            </div>
        )
    );
}

export default ListingDetails;