import { createSlice } from '@reduxjs/toolkit';
import getDefaultPaymentData from 'utils/getDefaultPaymentData';

const initialState = getDefaultPaymentData();

const paymentDataSlice = createSlice({
  name: 'paymentData',
  initialState,
  reducers: {
    setDefaultPaymentData: () => initialState, 
    setPaymentData: (state, action) => {
      return action.payload;
    },
    updatePaymentData: (state, action) => {
      const { property, value } = action.payload;
      return {
        ...state,
        [property]: value,
      };
    },
  },
});

export const { setDefaultPaymentData, setPaymentData, updatePaymentData } = paymentDataSlice.actions;
export default paymentDataSlice.reducer;