import React from 'react';
import styles from './HeroSection.module.css';

const HeroSection = ({imageUrl, title = '', children }) => {

  return (
    <section className={styles['hero-section']}>
      <img className={styles['hero-image']} src={imageUrl} alt=''/>
      {children ? <>{children}</> : <h1 className={styles['hero-title']}>{title}</h1>}

    </section>
  );
}

export default HeroSection;