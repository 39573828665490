import React, { useState } from 'react';
import styles from './ControlBar.module.css';
import { AiOutlineUnorderedList } from "react-icons/ai";
import { ImMap2 } from "react-icons/im";
import { LuSettings2 } from "react-icons/lu";
import MDropdown from 'components/shared/MDropdown/MDropdown';
import getOrderOptions from 'utils/getOrderOptions';

const ControlBar = ({isListingActive, onListingActiveChange, onOrderChange }) => {    
  const orderOptions = getOrderOptions();

  const defaultOrder = orderOptions[0].key

  const handleDropdownChange = (key) => {
    onOrderChange((!key ? null : orderOptions[key - 1].value))
  };

  const handleClick = (isListingActive) => {
    onListingActiveChange(isListingActive)
  }

  // sort by default at page load
  // React.useEffect(() => {
  //   onOrderChange(orderOptions[0].value)
  // }, [])

  return (
    <div className={styles['control-bar']}>
      <div className={styles['switch-wrapper']}>
      <div className={!!isListingActive ? styles.active : ''} onClick={() => handleClick(true)}>
        <span><AiOutlineUnorderedList /></span>
        <span>List View</span>
      </div>
      <div className={!isListingActive ? styles.active : ''} onClick={() => handleClick(false)}>
        <span><ImMap2 /></span>
        <span>Map View</span>
      </div>
      </div>
      <div className={styles.order}>
        <span><LuSettings2 /></span>
        <MDropdown options={orderOptions} label="Order By" onChange={handleDropdownChange} defaultValue={""} />
      </div>
    </div>
  );
}

export default ControlBar;