function getTimePeriods() {
    const timePeriods = [];

    for (let hour = 0; hour < 24; hour++) {
        const period = {
            key: hour,
            label: `${hour === 0 ? 12 : hour > 12 ? hour - 12 : hour}:00 ${hour < 12 ? 'am' : 'pm'} - ${(hour + 1) === 24 ? 12 : (hour + 1) % 12 === 0 ? 12 : (hour + 1) % 12}:00 ${(hour + 1) % 24 < 12 ? 'am' : 'pm'}`
        };
        timePeriods.push(period);
    }

    return timePeriods;
}

export default getTimePeriods;
