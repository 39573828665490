import {useState, useEffect} from 'react';
import axios from 'axios';

const useFetchListingDetails = (
    id,
    checkIn,
    checkOut,
    adults
) => {
    const [listing, setListing] = useState({});

    const endpoint = `/listings/${id}?include=pricing&checkIn=${checkIn}&checkOut=${checkOut}&adults=${adults}`;


    useEffect(() => {
        const fetchListing = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`);
                const {data}   = response
                setListing(data);
            }
            catch (error) {
                console.error('Error fetching listing details:', error);
            }
        };

        fetchListing();
    }, []);

    return {listing};
};

export default useFetchListingDetails;