import React from 'react';
import styles from './MModal.module.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const MModal = ({ width, open, onClose, children }) => {

  return (
    <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={width === 'adaptive' ? {} : {width: width}} className={styles['modal-wrapper']}>
            {children}
        </Box>
    </Modal>
  );
}

export default MModal;