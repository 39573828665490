import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from './PaymentModal.module.css';
import axios from 'axios';
import {useSelector, useDispatch} from 'react-redux';
import {setPaymentData} from 'redux/paymentDataSlice';
import {setCurrentListing} from 'redux/currentListingSlice';
import MModal from 'components/shared/MModal/MModal';
import HeroSection from 'components/HeroSection/HeroSection';
import UnderHeroSection from './UnderHeroSection/UnderHeroSection';
import AmenitiesSection from './AmenitiesSection/AmenitiesSection';
import GuestDetails from './GuestDetails/GuestDetails';
import PaymentInformation from './PaymentInformation/PaymentInformation'
import logo from 'assets/logo.png'
import MCheckBox from 'components/shared/MCheckBox/MCheckBox';
import Button from 'components/shared/Button/Button';
import {LiaTimesSolid} from "react-icons/lia";
import getDefaultPaymentData from 'utils/getDefaultPaymentData';
import {loadScript} from '@guestyorg/tokenization-js';
import {debounce} from 'lodash';


const PaymentModal = ({
                          open,
                          onClose
                      }) => {

    const dispatch = useDispatch()
    const {
              files,
              address,
              number_of_guests,
              bedrooms,
              bathrooms,
              airbnb_id
          }        = useSelector(state => state.currentListing);
    const {
              total,
              price,
              subTotal,
              checkIn,
              checkOut
          }        = useSelector(state => state.paymentData);

    const amenitiesData = {
        location:  address.city,
        sleeps:    number_of_guests,
        bedrooms:  bedrooms,
        bathrooms: bathrooms
    }

    const [guest, setGuest]                                 = useState({})
    const [card, setCard]                                   = useState({})
    const [paymentIsValid, setPaymentIsValid]               = useState(false)
    const [isTokenizing, setIsTokenizing]                   = useState(false)
    // const [paymentFormRendered, setPaymentFormRendered]     = useState(false)

    const initializeGuestyTokenization = async() => {
        console.log("Loading the Guesty Tokenization JS SDK script")
        try {
            window.guestyTokenization = await loadScript();
            await renderPaymentForm();
        }
        catch (error) {
            console.error("Failed to load the Guesty Tokenization JS SDK script", error);
        }
    }

    const renderPaymentForm = async () => {

        // if (paymentFormRendered) return

        // setPaymentFormRendered(true)

        try {
            // const guestyTokenizationVar = window.guestyTokenization;
            // if (paymentFormRendered) guestyTokenizationVar.destroy();
            await window.guestyTokenization.render({
                                             containerId: "guesty-tokenization-container",
                                             providerId:  "63e4e0892c4bd1002daa0ab8",
                                             amount:      total,
                                             // amount:      0.01,
                                             currency:    "USD",
                                             styles:      {
                                                 // fontSizeMd:              14,
                                                 // fontSizeLg:              28,
                                                 fontFamily: "Arial",
                                                 // mobileBreakpoint: "1400px"
                                             },
                                                       // showSupportedCards:   false,
                                             // showPciCompliantLink: false,
                                             initialValues:           {
                                                 // firstName: guest.firstName,
                                                 // lastName:  guest.lastName,
                                                 // address:   guest.address,
                                                 // city:      guest.city,
                                                 // zip:       guest.zip,
                                                 // country:   {
                                                 //        code: "US"
                                                 // }
                                             },

                                             // showInitialValuesToggle: true,
                                             onStatusChange:          (status) => {
                                                 console.log(status);
                                                 setPaymentIsValid(status)
                                              },
                                         });


        }
        catch (error) {
            console.error("Failed to re-render", error);
        }
    }


    // const debounceRenderPaymentForm = useCallback(debounce(renderPaymentForm, 1000), [guest]);

    const updateGuest = (guestData) => {
        setGuest(guestData)
        console.log(guestData);
    }

    const updateCard = (cardData) => {
        setCard(cardData)
    }

    const [loading, setLoading]   = useState(false);
    const [response, setResponse] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();

        setIsTokenizing(true)

        window.guestyTokenization.submit().catch((error) => {
            alert("Error occurred while processing the payment:" + error.response.data.message);
        }).then(async (response) => {

            setIsTokenizing(false)

            if (!response) return false;

            setLoading(true);

            const tokenizedCardId = response._id;

            //  Send the payment method to the backend to charge it.
            const params = {
                listingId:       airbnb_id,
                tokenizedCardId: tokenizedCardId,
                firstName:       guest.firstName,
                lastName:        guest.lastName,
                address:         guest.address,
                city:            guest.city,
                zip:             guest.zip,
                phone:           guest.phone,
                email:           guest.email,
                // amount:          0.01,
                amount:          total,
                subtotal:       subTotal,
                checkInDate:  checkIn,
                checkOutDate: checkOut
            }

            const endpoint = `/listings/pay`

            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`, params);
                setResponse(res.data);
            }
            catch (error) {
                console.error('Error occurred while sending request to the API:', error);
            }
            finally {
                setLoading(false); // Stop loading
            }

        });

    };

    const handleModalClose = () => {
        // dispatch(setPaymentData(getDefaultPaymentData()));
        // dispatch(setCurrentListing({}));
        onClose();
    };

    const checkBoxOptions = [
        {
            key:   0,
            label: "I have read and agree to the terms & conditions and the cancellation policy"
        }
    ]

    const [agree, setAgree]   = useState(false)
    const handleAgreeCheckBox = (selected) => {
        setAgree(selected.length)
    }

    const modalWidth = loading || response ? 924 : 'adaptive'

    // useEffect(() => {
    //     debounceRenderPaymentForm()
    // }, [guest]);

    //  Run initializeGuestyTokenization only once when the component is rendered
    useEffect(() => {
        initializeGuestyTokenization().then(r => console.log(r));
    }, []);

    return (
        <MModal width={modalWidth} open={open} onClose={handleModalClose}>
            {loading || response ? (
                <div className={styles["payment-done-wrapper"]}>
                    <LiaTimesSolid
                        onClick={handleModalClose}
                        className={styles["close-icon"]}
                        style={{color: "#181818"}}
                    />
                    <div className={styles["icon-section"]}>
                        <img src={logo} alt=""/>
                    </div>
                    <h3>
                        {response && response.paymentReport && response.paymentReport.status === "FAILED" ? `Error: ${response && response.paymentReport.attempts[0].payload.ResponseDescription}` : response && !response.error ? "Your reservation is confirmed!" : ""}
                        {response && response.error ? `Error: ${response.error}` : ""}
                    </h3>
                    <div className={styles["details-section"]}>
                        {loading ? (
                            <h2>Loading</h2>
                        ) : (
                             <>
                                 <h4 className={styles["details-title"]}>DETAILS</h4>
                                 <div className={styles["details-box"]}>
                                     <div className={styles["details-item"]}>
                                         <div>Booking number:</div>
                                         <div>#{response?.reservation?._id}</div>
                                     </div>
                                     <div className={styles["details-item"]}>
                                         <div>Total amount:</div>
                                         <div>${response.amount}</div>
                                     </div>
                                 </div>
                             </>
                         )}
                    </div>
                </div>
            ) : (
                 <div className={styles["payment-modal-wrapper"]}>
                     <LiaTimesSolid
                         onClick={handleModalClose}
                         className={styles["close-icon"]}
                         style={{color: "white"}}
                     />
                     {/*<HeroSection imageUrl={files[0].url}>*/}
                     {/*  <h1 className={styles["hero-title"]}>{title}</h1>*/}
                     {/*</HeroSection>*/}
                     <UnderHeroSection/>
                     {/*<AmenitiesSection amenities={amenitiesData} />*/}

                     <form onSubmit={handleSubmit} className={styles["main-section"]}>
                         <div className={styles['payment-info-wrapper']}>
                             {/*<PaymentInformation*/}
                             {/*  onCardChange={updateCard}*/}
                             {/*/>*/}
                             <div id="guesty-tokenization-container"></div>
                         </div>
                         <div>
                             <GuestDetails
                                 onGuestChange={updateGuest}
                             />
                             <div className={styles["conditions-checkbox"]}>
                                 <MCheckBox
                                     isRequired={true}
                                     options={checkBoxOptions}
                                     onSelectionChange={handleAgreeCheckBox}
                                 />
                             </div>
                             <div className={styles["submit-section"]}>
                                 {/* use agree state here to  */}
                                 <Button
                                     handleClickFunc={() => {}}
                                     disabled={!paymentIsValid || isTokenizing}
                                     btnType='submit'
                                 >
                                     {isTokenizing ? "Processing..." : "Book Now"}
                                 </Button>
                             </div>
                         </div>
                     </form>

                 </div>
             )}
        </MModal>
    );
}

export default PaymentModal;