import React from 'react';
import styles from './Footer.module.css';
import logo from 'assets/logoWhite.png'
import FooterColumn from './FooterColumn/FooterColumn';
import { Link } from 'react-router-dom';
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import { VscSend } from "react-icons/vsc";


const Footer = () => {
  // 
  const curYear = new Date().getFullYear();
  const navigationLinks = [
    {title: 'Home', url: process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH + '/home'},
    {title: 'About', url: process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH + '/about'},
    {title: 'Property Management', url: process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH + '/property-management'},
    {title: 'FAQ', url: process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH + '/faq'},
    {title: 'Contact', url: process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH + '/contact'}
  ];
  const contactUsLinks = [
    {title: (
      <>
        <HiOutlineLocationMarker /> Indiana, Kentucky & Ohio
      </>
    ), url: null},
    {title: 
      (
        <>
          <BsTelephone /> +1 (317) 203-9215
        </>
      ), url: 'tel:+13172039215'},
    {title: 
      (
        <>
          <BsEnvelope /> welcome@homebasebnbs.com
        </>
      ), url: 'mailto:welcome@homebasebnbs.com'},
  ];
  const subscribeLinks = [
    {title: 'Subscribe to our newsletter', url: '#'},
    {title: (
      <div className={styles['email-box']}>
        <span>Email</span> 
        <VscSend /> 
      </div>
    ), url: '#'},
  ];


  return (
    <div className={styles['footer-section']}>
      <div className={styles['footer-main']}>
        <div className={styles['footer-logo']}>
            <a href={process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH}><img className={styles.logo} src={logo} alt="" /></a>
        </div>
        <FooterColumn title="Navigation" links={navigationLinks}/>
        <FooterColumn title="Contact Us" links={contactUsLinks}/>
        <FooterColumn title="Subscribe" links={subscribeLinks}/>
      </div>
      <div className={styles.line}></div>
      <div className={styles['footer-legal']}>
        <p className={styles['all-rights-text']}>©{curYear} Home Base. All Rights Reserved.</p>
        <Link className={styles.link} to={process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH + "/terms-of-use"}>Terms of Use</Link>
        <Link className={styles.link} to={process.env.REACT_APP_ABSOLUTE_STATIC_SITE_PATH + "/privacy-policy"}>Privacy Policy</Link>
      </div>
    </div>
  );
}

export default Footer;