import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setCurrentListing} from 'redux/currentListingSlice';
import {setPaymentData} from 'redux/paymentDataSlice';
import styles from './FixedCard.module.css'
import MDropdown from 'components/shared/MDropdown/MDropdown';
import Button from 'components/shared/Button/Button';
import PaymentModal from 'components/PaymentModal/PaymentModal';
import getOptionsForNumberOfGuests from 'utils/getOptionsForNumberOfGuests';
import calculatePaymentData from 'utils/calculatePaymentData';
import * as fecha from "fecha";
import HotelDatepicker from "hotel-datepicker";
import "hotel-datepicker/dist/css/hotel-datepicker.css";
import TextField from "@mui/material/TextField";

const FixedCard = ({listingData}) => {

    const opts = getOptionsForNumberOfGuests(listingData?.number_of_guests);

    const dispatch = useDispatch();

    const initialPaymentData                      = useSelector((state) => (
        {...state.paymentData}
    ));
    const [paymentDataLocal, setPaymentDataLocal] = useState(initialPaymentData);

    // no need to set state for calculated at this point
    const calculated               = calculatePaymentData(listingData, paymentDataLocal);
    const completePaymentDataLocal = {...paymentDataLocal, ...calculated}

    const handleDropdownChange = (
        property,
        value
    ) => {
        setPaymentDataLocal(prevPaymentDataLocal => (
            {
                ...prevPaymentDataLocal,
                [property]: value
            }
        ));
    };

    const handleDateChange = () => {

        let value = window.datepicker.getValue();

        // The value is in format "MM/DD/YYYY - MM/DD/YYYY", so we need to split it.

        let dates    = value.split(" - ");
        let checkIn  = dates[0];
        let checkOut = dates[1];

        setPaymentDataLocal(prevPaymentDataLocal => (
            {
                ...prevPaymentDataLocal,
                checkIn:  checkIn,
                checkOut: checkOut
            }
        ));

    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        dispatch(setCurrentListing(listingData));
        dispatch(setPaymentData(completePaymentDataLocal))
        setIsModalOpen(true);
    };

    // Get all form values from the URL parameters
    const queryParameters = new URLSearchParams(window.location.search);
    const checkIn         = queryParameters.get('checkIn');
    const checkOut        = queryParameters.get('checkOut');
    const adults          = queryParameters.get('adults');
    const children        = queryParameters.get('children');

    // Set the form values to the state
    useEffect(() => {
        setPaymentDataLocal(prevPaymentDataLocal => (
            {
                ...prevPaymentDataLocal,
                checkIn:  checkIn,
                checkOut: checkOut,
                adults:   adults,
                children: children
            }
        ))
    }, [listingData]);

    // Get disabled dates from the listing's listing_exception and reservations objects.
    // We will loop through the listing_exceptions and add dates when the object has a date property and a status of
    // "blocked". We will loop through the reservations, loop through each day between check in and check out, and add
    // those dates to the disabledDates array, except for the check out date.

    const disabledDates = [];

    if (listingData?.listing_exceptions) {
        listingData.listing_exceptions.forEach((exception) => {
            if (exception.date && exception.blocked) {
                disabledDates.push(exception.date);
            }
        });
    }

    if (listingData?.reservations) {
        listingData.reservations.forEach((reservation) => {
            if (reservation.status === "confirmed" || reservation.status === "reserved") {
                const reservationCheckInDate  = new Date(reservation.check_in_date + "T00:00:00");
                const reservationCheckOutDate = new Date(reservation.check_out_date + "T00:00:00");
                for (
                    let i = reservationCheckInDate;
                    i < reservationCheckOutDate;
                    i.setDate(i.getDate() + 1)
                ) {
                    disabledDates.push(fecha.format(i, "YYYY-MM-DD"));
                }
            }
        });
    }


    const myRef = React.createRef();

    useEffect(() => {

        // You can work with the ref here
        console.log(disabledDates)
        let options = {
            format:         "MM/DD/YYYY",
            autoClose:      true,
            startDate:      fecha.format(new Date(), "MM/DD/YYYY"),
            disabledDates:  disabledDates,
            onSelectRange:  handleDateChange,
            moveBothMonths: true,
        };

        if (disabledDates.length && !window.datepicker) {
            window.datepicker = new HotelDatepicker(myRef.current, options);
            window.datepicker.setRange(new Date(checkIn), new Date(checkOut));
        }

    }, [listingData]);

    return (
        <div className={styles.card}>
            <div className={`${styles['adult-children-row']} ${styles.wrapper} ${styles.row}`}>
                <div>
                    <div className={styles['row-title']}>ADULTS</div>
                    <MDropdown
                        options={opts} label=''
                        defaultValue={adults}
                        onChange={(value) => handleDropdownChange('adults', value)}
                    />
                </div>
                <div>
                    <div className={styles['row-title']}>CHILDREN</div>
                    <MDropdown
                        options={opts}
                        label=''
                        defaultValue={children}
                        onChange={(value) => handleDropdownChange('children', value)}
                    />
                </div>
            </div>
            <div className={`${styles['dates-row']} ${styles.row}`}>
                <div className={styles['row-title']}>DATES</div>
                <div className={styles['wrapper']} id={"dates_container"} ref={myRef}>

                    {/*<div className={styles['date-picker-box']}>*/}
                    {/*  <MDatepicker */}
                    {/*    onDateChange={(value) => handleDateChange('checkIn', value)}*/}
                    {/*    defaultDate={checkIn}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    {/*<div className={styles['date-picker-box']}>*/}
                    {/*  <MDatepicker */}
                    {/*    onDateChange={(value) => handleDateChange('checkOut', value)}*/}
                    {/*    defaultDate={checkOut}*/}
                    {/*    startDate={checkIn}*/}
                    {/*  />*/}
                    {/*</div>*/}

                    <TextField id={"dates"} label={"CHECK IN"} name={"dates"} placeholder="Dates"
                               fullWidth
                               variant="standard"
                               value={paymentDataLocal.checkIn}/>

                    <TextField id={"dates"} label={"CHECK OUT"} name={"dates"} placeholder="Dates"
                               fullWidth
                               variant="standard"
                               value={paymentDataLocal.checkOut}/>

                </div>
            </div>
            <div className={`${styles['info-box']} ${styles.row}`}>
                <div className={`${styles['wrapper']} ${styles['info-wrapper']}`}>
                    <div>Price:</div>
                    <div className={styles['info-item']}>${(
                        calculated?.subTotal / calculated?.days
                    ).toFixed(2)} per night
                    </div>
                </div>
                <div className={`${styles['wrapper']} ${styles['info-wrapper']}`}>
                    <div>Subtotal:</div>
                    <div className={styles['info-item']}>{calculated.subTotal ? "$" + calculated.subTotal.toFixed(
                        2) : "Select dates"}</div>
                </div>
                <div className={`${styles['wrapper']} ${styles['info-wrapper']}`}>
                    <div>Cleaning Fee:</div>
                    <div
                        className={styles['info-item']}>{listingData.cleaning_fee ? "$" + listingData.cleaning_fee.toFixed(
                        2) : "$0.00"}</div>
                </div>
                <div className={`${styles['wrapper']} ${styles['info-wrapper']}`}>
                    <div>Taxes & Fees:</div>
                    <div className={styles['info-item']}>{calculated.taxes ? "$" + (
                                                                             calculated.taxes + 15
                    ).toFixed(2) : "Select dates"}</div>
                </div>
            </div>
            <hr className={styles.divider}/>
            <div className={`${styles['total-row']} ${styles.wrapper} ${styles.row}`}>
                <div className={styles.total}>Total:</div>
                <div>{calculated.total ? "$" + calculated.total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }) : "Select dates"}</div>
            </div>
            <div className={styles['submit-row']}>
                <Button handleClickFunc={handleOpenModal}>Book Now</Button>
            </div>
            {isModalOpen && <PaymentModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />}
        </div>
    );
}

export default FixedCard;