import addDays from "./addDays";

const getDefaultPaymentData = () => {

    const defaultData = {
        checkIn: addDays(1),
        checkOut: addDays(3),
        adults: 1,
        children: 0
      };
    
    return defaultData
  };
    
export default getDefaultPaymentData;